@import url(https://fonts.googleapis.com/css?family=Exo:400,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  scroll-behavior: smooth;
}

* {
  margin: 0px;
  padding: 0px;
}
.circles {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

ol,
ul {
  margin: 0;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: red;
  -webkit-animation: animate 25s linear infinite;
          animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 12s;
          animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 18s;
          animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 140px;
  -webkit-animation-delay: 7s;
          animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  -webkit-animation-delay: 15s;
          animation-delay: 15s;
  -webkit-animation-duration: 45s;
          animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 35s;
          animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 140px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 11s;
          animation-duration: 11s;
}
.circles li:nth-child(11) {
  left: 65%;
  width: 75px;
  height: 75px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 11s;
          animation-duration: 11s;
}

@-webkit-keyframes animate {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    -webkit-transform: translateY(-1000px) rotate(720deg);
            transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

@keyframes animate {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    -webkit-transform: translateY(-1000px) rotate(720deg);
            transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

#projects {
  padding-top: 0px;
}
#content {
  padding: 50px;
}
#projects .card {
  cursor: pointer;
  max-height: 700px;
}
#projects .card:hover {
  background-color: #e9e9e9;
}
#projects .card__mobile-title {
  border-radius: 0.25rem;
}
#projects .card #link:hover {
  color: rgb(1, 3, 124);
}
#projects .card #link-wrap {
  white-space: nowrap;
  padding-right: 10px;
  overflow: hidden;
}
#projects .card__image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
  padding: 10px;
}
#projects .card__image::after {
  background-color: #ffffff00;
}
.desktop {
  display: block;
}
.mobile {
  display: none;
}
@media only screen and (max-width: 600px) {
  #projects {
    padding-top: 20px;
  }
  #content {
    padding: 20px;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}

.post {
  padding: 50px 500px;
  padding-top: 80px;
}
.post .img img {
  border-radius: 0.5rem;
  width: 80%;
  min-width: 200px;
  max-width: 1000px;
}
.post .img {
  margin: 20px 0;
  padding: 20px;
  background-color: rgb(240, 240, 240);
  border-radius: 0.5rem;
}
@media only screen and (max-width: 1400px) {
  .post {
    padding: 80px 50px;
    padding-top: 80px;
  }
}
@media only screen and (max-width: 600px) {
  .post {
    padding: 80px 50px;
    padding-top: 80px;
  }
}

#work {
  padding-top: 0px;
}
#work .image img {
  border-radius: 0.25rem;
  width: 100%;
  max-height: 400px;
}
#work a {
  padding: 0px;
  color: #147580;
}
#work #timePeriod {
  margin-top: -10px;
  color: #516f72;
}
@media only screen and (max-width: 600px) {
  #work {
    padding-top: 20px;
  }
}

.demo-card {
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}
.demo-card:hover {
  cursor: pointer;
  -webkit-filter: brightness(0.95);
          filter: brightness(0.95);
}
.demo-card img {
  border-radius: 10px;
  align-self: center;
}
.active-card {
  border: 10px solid #14444c28;
  border-radius: 20px;
}

