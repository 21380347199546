.demo-card {
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}
.demo-card:hover {
  cursor: pointer;
  filter: brightness(0.95);
}
.demo-card img {
  border-radius: 10px;
  align-self: center;
}
.active-card {
  border: 10px solid #14444c28;
  border-radius: 20px;
}
