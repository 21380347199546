#work {
  padding-top: 0px;
}
#work .image img {
  border-radius: 0.25rem;
  width: 100%;
  max-height: 400px;
}
#work a {
  padding: 0px;
  color: #147580;
}
#work #timePeriod {
  margin-top: -10px;
  color: #516f72;
}
@media only screen and (max-width: 600px) {
  #work {
    padding-top: 20px;
  }
}
